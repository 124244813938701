import React from 'react';

export default ({
  id, title, children, ...props
}) => (
  <>
    <input id={id} className="hide-input hide-option-list" type="checkbox" />
    <div className="group" {...props}>
      <label htmlFor={id} className="group-hide-link">&nbsp;</label>
      <div className="group-header">{title}</div>
      {children}
    </div>
  </>
);
