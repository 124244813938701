import { Texture, Vector3 } from '@babylonjs/core';
import React from 'react';
import CONFIG from '../../config';

const LoaderNotAnimation = ({ xPosition, visible }) => {
  const loaderTexture = new Texture(`${CONFIG.BASE_PATH}/static/icons/loader-icon.svg`);
  return (
    <plane
      name="loader-plane"
      position={new Vector3(xPosition, 1, -2)}
      rotation={new Vector3(0, Math.PI, 0)}
      isVisible={visible}
    >
      <standardMaterial
        name="loaderMaterial"
        height={1024}
        width={1024}
        opacityTexture={loaderTexture}
        diffuseTexture={loaderTexture}
      />
    </plane>
  );
};

export default LoaderNotAnimation;
