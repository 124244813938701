import React, { Suspense, useEffect, useState } from 'react';
import * as BABYLON from "@babylonjs/core";
import { SkyMaterial } from "@babylonjs/materials";

import {
  Scene as SceneBabylon, Engine, withBabylonJS
} from 'react-babylonjs';
import {
  Vector3, Color3, Database
} from '@babylonjs/core';
import { useSelector } from 'react-redux';
import Model from './Model';
import CONFIG from '../config';
import LoaderNotAnimation from './scene/LoaderNotAnimation';
import { ShadowGenerator } from '@babylonjs/core';
import { CubeTexture } from '@babylonjs/core';

/*
  WORD measurement units
*/
const unitSize = 1; // 1 unit = 1метр
const wordSize = 2000 * unitSize; // размер мира
const roomSize = [8 * unitSize, 6 * unitSize, 3.5 * unitSize]; // размер комнаты (Д*Ш*В)
const upWindowSize = [1 * unitSize, 1 * unitSize]; // отступы верхнего окна от стен (Д*Ш)
const wallThickness = 0.4 * unitSize; // толщина стен (40 = 40см)
const RoomSizeMax = Math.max.apply(Math, roomSize); // размер комнаты по наибольшей стороне

// размеры модельки (очень важны!)
// размеры по оям сцены! или X(Red) Y(Green) Z(Blue) если включить asis
// для кровати это Ш*В*Д
// const modelSize = [1.77 * unitSize, 0.7 * unitSize, 2.1 * unitSize];

const TRANSITION_DURATION = 2000;
const TARGET_POSITION_FRACTION = 0.2;

const animate = (a, b, scale) => (b - a) * scale + a;
const easeInOutSine = (t) => -(Math.cos(Math.PI * t) - 1) / 2;
const compareCameraParams = (a, b) => {
  const posa = a.position;
  const posb = b.position;
  const tara = a.target;
  const tarb = b.target;
  const position = posa.length && posb.length && posa[0] === posb[0] && posa[1] === posb[1] && posa[2] === posb[2];
  const target = tara.length && tarb.length && tara[0] === tarb[0] && tara[1] === tarb[1] && tara[2] === tarb[2];
  const fov = a.fov === b.fov;
  return position && target && fov;
};
const checkOnNewGroup = (state, prevState) => {
  const issetParams = state.parentGroupId || prevState.parentGroupId;
  if (!!issetParams) return state.parentGroupId !== prevState.parentGroupId;
  return true;
};

const CameraController = ({ cameraParams, cameraParamsPrev, isVisibleRoom }) => {
  const { camera } = window;

  useEffect(() => {
    if (camera) {
      camera.inputs.attached.pointers.buttons = [0];
    }
  }, [camera]);

  useEffect(() => {
    if (camera) {
      const startTime = new Date().getTime();
      const newPrevious = !compareCameraParams(cameraParams, cameraParamsPrev);
      const newGroup = checkOnNewGroup(cameraParams, cameraParamsPrev);
      const { x, y, z } = camera.position;
      const currentCameraParams = {
        position: [x, y, z],
        target: cameraParamsPrev.target,
        fov: camera.fov
      };
      const convertFOV = (fov) => (fov * 1.75) / 100;
      const animateCamera = () => {
        const delta = new Date().getTime() - startTime;
        if (newGroup) {
          if (newPrevious && cameraParams.useTransition && delta <= TRANSITION_DURATION) {
            const d = delta / TRANSITION_DURATION;
            const fr = TARGET_POSITION_FRACTION;
            const dt = easeInOutSine(d <= fr ? d / fr : 1); // first half of animation: camera target
            const [ctx, cty, ctz] = currentCameraParams.target;
            const [ntx, nty, ntz] = cameraParams.target;
            camera.target = new Vector3(animate(ctx, ntx, dt), animate(cty, nty, dt), animate(ctz, ntz, dt));
            const dp = easeInOutSine(d > fr ? (d - fr) / (1 - fr) : 0); // seconf half of animation: camera position
            const [cx, cy, cz] = currentCameraParams.position;
            const [nx, ny, nz] = cameraParams.position;
            camera.position = new Vector3(animate(cx, nx, dp), animate(cy, ny, dp), animate(cz, nz, dp));
            const sf = currentCameraParams.fov;
            const f = convertFOV(cameraParams.fov);
            camera.fov = animate(sf, f, d);
            window.requestAnimationFrame(animateCamera);
          } else {
            camera.position = new Vector3(...cameraParams.position);
            camera.target = new Vector3(...cameraParams.target);
            camera.fov = convertFOV(cameraParams.fov);
          }
        }
      };

      animateCamera();
    }
  }, [cameraParams, cameraParamsPrev]);
  return (
    <arcRotateCamera
      name="arc"
      target={new Vector3(0, 1, 0)}
      alpha={-Math.PI / 2}
      beta={(0.5 + (Math.PI / 4))}
      radius={15}
      minZ={0.001}
      wheelPrecision={50}
      lowerRadiusLimit={1 * unitSize}
      upperRadiusLimit={roomSize[0] / 2}
      upperBetaLimit={Math.PI / 2}
      // upperAlphaLimit={2 + Math.PI / 2}
      // lowerAlphaLimit={-Math.PI / 2 + 1.171}
    />
  );
};

const body = document.querySelector('body');
let scrollTop;

const disableScrolling = () => {
  scrollTop = window.pageYOffset;
  body.style.top = -scrollTop + 'px';
  body.classList.add('no-scroll');
}

const enableScrolling = () => {
  body.classList.remove('no-scroll');
  window.scrollTo(0, scrollTop);
  body.style.top = null;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const getPanelDimensions = (panelRef, windowWidth) => {
  let w = 0;
  if (windowWidth > CONFIG.MIN_DESKTOP_WIDTH) {
    if (panelRef.current) w = panelRef.current.clientWidth;
    else w = 392;
  }
  return {
    spw: w
  };
};

const onSceneMount = (e) => {
  const { scene, canvas } = e;

  Database.IDBStorageEnabled = true;
  const environment = scene.createDefaultEnvironment();

  scene.autoClear = false;

  window.environment = environment;
  window.scene = scene;
  window.canvas = canvas;

  canvas.addEventListener('mouseenter', disableScrolling);

  canvas.addEventListener('mouseleave', enableScrolling);

  // Skybox
  const skyMaterial = new SkyMaterial("skyMaterial", scene);
  skyMaterial.backFaceCulling = false;
  skyMaterial.luminance = 0.1;
  skyMaterial.azimuth = 0.1;
  skyMaterial.inclination = 0.1;
  const skyBox = BABYLON.Mesh.CreateBox("skyBox", 1000.0, scene);
  skyBox.material = skyMaterial;
  skyBox.isVisible = false;

  window.skyBox = skyBox;

  // Debug Block
  const debugBlock = document.getElementById('debug-layer');
  debugBlock.style.display = 'none';

  if (CONFIG.NODE === 'development') {
    import('@babylonjs/inspector').then(() => {
      debugBlock.style.display = 'flex';
      scene.debugLayer.show({
        globalRoot: debugBlock
      });
    });
  }

  scene.getEngine().runRenderLoop(() => {
    if (scene) {
      scene.render();
    }
  });
};

const useDimensions = (panelRef) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [panel, setPanel] = useState(getPanelDimensions(panelRef, windowDimensions.width));

  useEffect(() => {
    const handleResize = () => {
      const window = getWindowDimensions();
      setWindowDimensions(window);
      setPanel(getPanelDimensions(panelRef, window.width));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return Object.assign(windowDimensions, panel);
};

const Scene = ({ panelRef }) => {
  const {
    specs,
    modelName,
    visibleNodes,
    cameraParams,
    cameraParamsPrev,
    isVisibleRoom,
    modelParams: { position, rotation }
  } = useSelector((state) => state.model);


  useEffect(() => {
    if (window.scene) {
      window.camera = window.scene.activeCamera;
      const sunLight = window.scene.lights.find(obj => obj.id === 'sunLight');
      const shadowGenerator = new ShadowGenerator(1024, sunLight);
      shadowGenerator.useBlurExponentialShadowMap = true;
      shadowGenerator.useKernelBlur = true;
      shadowGenerator.blurKernel = 32;
      shadowGenerator.depthScale = 100;
      shadowGenerator.darkness = 0.3;
      shadowGenerator.forceBackFacesOnly = true;
      window.shadowGenerator = shadowGenerator;
    }
  }, [window.scene]);

  const changeEnvironmentScene = () => {
    const { scene, environment, skyBox } = window;
    if (scene && environment && skyBox) {
      const studioTexture = CubeTexture.CreateFromPrefilteredData(`${CONFIG.BASE_PATH}/static/texture/environment/studio.env`, scene);
      const environmentSpecularTexture = CubeTexture.CreateFromPrefilteredData(`${CONFIG.BASE_PATH}/static/texture/environment/environmentSpecular.env`, scene);
      const changeEnvironment = (environment, options) => {
        environment.updateOptions(options);
        environment.rootMesh.position = Vector3.Zero();
        environment.skybox.isVisible = skyBox.isVisible;
        skyBox.isVisible = !skyBox.isVisible;
      };
      if (!isVisibleRoom) {
        changeEnvironment(environment, {
          groundColor: Color3.White()
        });
        scene.environmentTexture = studioTexture;
        scene.intensity = 1.6;
      } else {
        changeEnvironment(environment, {
          groundColor: new Color3(0.09, 0.09, 0.2)
        });
        scene.environmentTexture = environmentSpecularTexture;
        scene.intensity = 1;
      }
    }
  };

  useEffect(() => {
    changeEnvironmentScene();
  }, [window.scene, isVisibleRoom]);

  const { width: w, height: h, spw } = useDimensions(panelRef);

  const xPosition = spw ? ((w + spw - w) / w) * 2 : 0;

  return (
    <Engine antialias adaptToDeviceRatio engineOptions={{ preserveDrawingBuffer: true }} canvasId="sample-canvas">
      <SceneBabylon onSceneMount={onSceneMount}>
        <directionalLight
          name="sunLight"
          setDirectionToTarget={[Vector3.Zero()]}
          direction={Vector3.Zero()}
          position={new Vector3(
            Math.sqrt(2) * (RoomSizeMax / 3),
            Math.sqrt(2) * (RoomSizeMax / 1.15),
            Math.sqrt(2) * (RoomSizeMax / 1.65)
          )}
          shadowMinZ={1}
          shadowMaxZ={2500}
          intensity={1.6}
        />

        <CameraController cameraParams={cameraParams} cameraParamsPrev={cameraParamsPrev}
                          isVisibleRoom={isVisibleRoom}/>
        <Suspense fallback={<LoaderNotAnimation visible="true" xPosition={xPosition}/>}>
          <Model
            xPosition={xPosition}
            position={new Vector3(...position)}
            visibleNodes={visibleNodes}
            specs={specs}
            modelName={modelName}
            rotation={new Vector3(...rotation)}
          />
        </Suspense>
      </SceneBabylon>
    </Engine>
  );
};

export default withBabylonJS(Scene);
