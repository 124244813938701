import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ui/ui.css';
import html2canvas from 'html2canvas';
import Group from './ui/Group';
import OptionList from './ui/OptionList';
import {
  dispatchUIAction,
  dispatchResetProps,
  dispatchSetPreset,
  dispatchExportVrAction,
  dispatchExportBasketAction
} from '../store/uiSlice';
import { setCameraToBeginning, setModelName } from '../store/modelSlice';
import CONFIG from '../config';
import PresetList from './ui/PresetList';
import HomeButton from './ui/HomeButton';
import Fetching from './ui/Fetching';

// for obtain component by string name
const componentsMap = {
  OptionList
};

const UI = ({ panelRef }) => {
  const dispatch = useDispatch();

  const { modelName, isModelEditing, fetching } = useSelector((state) => state.model);

  const isHomePage = modelName === CONFIG.HOME_NAME;

  const {
    title,
    groups,
    presets, amount, buttonVrProcessClass, buttonBasketProcessClass
  } = useSelector((state) => state.ui);

  const renderGroups = (groups = []) => groups.map((g) => {
    const key = `group-${g.id}`;
    return g.type === 'ProductionGroup'
      ? <div key={key}>{g.content.map((gg) => renderList(gg, handleProduction))}</div>
      : <Group key={key} id={g.id} title={g.title}>{g.content.map((gg) => renderList(gg))}</Group>;
  });

  const renderPresets = (presets = []) => {
    const id = groups.length + 1;
    const key = `presets-${id}`;
    return <Group key={key} id={id} title="Пресеты"><PresetList items={presets} eventHandler={handlePreset} /></Group>;
  };

  const ResetButton = ({ onClick, children }) => (
    <button className="button button_gray button_border button_block" type="reset" onClick={onClick}>
      { children }
    </button>
  );

  const renderList = ({ component, props }, eventHandler = handleAction) => {
    const Element = componentsMap[component];
    return <Element key={`content-${Math.random()}`} {...props} eventHandler={eventHandler} />;
  };

  const getUrlToScreenshot = async () => {
    const canvas = document.querySelector('#furniture-configurator-widget canvas');
    let base64image = null;
    await html2canvas(canvas).then((canvas) => {
      base64image = canvas.toDataURL('image/jpeg');
    });
    return base64image;
  };

  const onReset = () => {
    dispatch(dispatchResetProps({}));
  };
  const onHandleExportVr = (e) => {
    e.preventDefault();
    dispatch(dispatchExportVrAction({}));
    return false;
  };

  const handlePreset = (e) => {
    const presetId = e.target.id;
    dispatch(dispatchSetPreset({ presetId }));
  };

  const handleProduction = (e, modelName = null) => {
    if (!modelName) {
      modelName = e.target.dataset.modelName;
    }
    dispatch(setModelName({ modelName }));
  };

  const handleAction = (e) => {
    const listId = e.target.name;
    const itemId = e.target.id;
    dispatch(dispatchUIAction({ listId, itemId }));
  };


  return (
    <div className="panel" ref={panelRef}>
      {
        !isHomePage && !isModelEditing
          ? <HomeButton handleAction={handleProduction} />
          : null
      }
      <form className="panel-form" action="">
        <div className="panel-wrapper">
          <div className="panel-top">
            <div className="panel-top__left">
              <div className="info-header">{!isHomePage ? 'редактировать' : 'выбрать'}</div>
              <div className="header">{title}</div>
            </div>
            <div className={`panel-top__right ${isHomePage ? 'hidden' : ''}`}>
              {/* <a
                className={`button button_blue button_border ${buttonVrProcessClass}`}
                href="/#"
                onClick={onHandleExportVr}
              >
                <img className="button__icons" src={`${CONFIG.BASE_PATH}/static/icons/icon-vr.svg`} alt="VR" />
                vr
              </a> */}
            </div>
          </div>
          <div className="panel-center">
            <Fetching fetching={fetching}>
              <div className="panel-scroll">
                <div className="panel-scroll__wrapper">

                  {renderGroups(groups)}

                  {presets && presets.length
                    ? renderPresets(presets)
                    : null}

                  {!isHomePage
                    ? <ResetButton onClick={onReset}>Сбросить</ResetButton>
                    : null}
                </div>
              </div>
            </Fetching>

          </div>
          <div className="panel-bottom">
            <div className="panel-bottom__left">
              <div className="info-header">Цена от:</div>
              <div className="header">
                {amount}
                {' '}
                &#8381;
              </div>
            </div>
            {
              !isHomePage
                ? (
                  <div className="panel-bottom__right">
                    <button
                      className={`button button_yellow  ${buttonBasketProcessClass}`}
                      type="button"
                      /* onClick={async (e) => {
                        e.preventDefault();
                        await dispatch(setCameraToBeginning());
                        setTimeout(async () => {
                          const picture = await getUrlToScreenshot();
                          dispatch(dispatchExportBasketAction(picture));
                        }, 50);
                        return false;
                      }} */
                    >
                      <img className="button__icons" src={`${CONFIG.BASE_PATH}/static/icons/icon-cart.svg`} alt="add to shopping cart" />
                      в корзину
                    </button>
                  </div>
                )
                : null
            }
          </div>
        </div>
      </form>
    </div>
  );
};

export default UI;
