import { Vector3 } from '@babylonjs/core';
import React from 'react';

const ErrorModel = ({ xPosition, error }) => (
  <>
    <plane
      name="error-plane"
      position={new Vector3(xPosition, 1, -2)}
      rotation={new Vector3(0, Math.PI, 0)}
    >
      <advancedDynamicTexture
        name="dialogTexture1"
        height={1024}
        width={1024}
        createForParentMesh
      >
        <textBlock
          name="description"
          key="error-desc"
          text="Error"
          color="red"
          fontSize={350}
        />
      </advancedDynamicTexture>
    </plane>
    <plane
      name="error-plane-message"
      position={new Vector3(xPosition, 0.6, -2)}
      rotation={new Vector3(0, Math.PI, 0)}
    >
      <advancedDynamicTexture
        name="dialogTexture2"
        height={1024}
        width={1024}
        createForParentMesh
      >
        <textBlock
          name="message"
          key="error-message"
          text={error}
          color="black"
          fontSize={300}
        />
      </advancedDynamicTexture>
    </plane>
  </>
);

export default ErrorModel;
