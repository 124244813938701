import CONFIG from "../../config";
import React from "react";

const PresetElement = ({ name, checked, id, eventHandler }) => {
    return (<label className="option__label">
        <input className="hide-input" id={id} type="checkbox" name={name} checked={checked} onChange={eventHandler}/>
        <div className="option__item">
            {<div className="option__name">{name}</div>}
            {checked && <div className="option__checked">
                <img src={`${CONFIG.BASE_PATH}/static/icons/icon-check.svg`} alt="выбрано"/></div>}
        </div>
    </label>);
};

export default PresetElement;