import React from "react";
import PresetElement from "./PresetElement";

const PresetList = ({ items, eventHandler }) => {
    return <div className='option-list'>
        {items.map(_ => <PresetElement id={_.id} name={_.name} key={`preset-it-${_.id}`}
                                       checked={_.checked} eventHandler={eventHandler}/>)}
    </div>;
};

export default PresetList;