import React from 'react';
import CONFIG from '../../config';

export default ({
  variant = '',
  name = '',
  items = [{ label: null, checked: false, icon: null }],
  value = null,
  eventHandler = () => {}
}) => {
  const OptionListClassName = (variant) => {
    const res = ['option-list'];
    switch (variant) {
      case 'big-font':
        res.push('option-list_big-font');
        break;
      case 'line':
        res.push('option-list_line');
        break;
      case 'round':
        res.push('option-list_round');
        break;
      case 'production':
        res.push('option-list_production');
        break;
      case '3d-icons':
        res.push('option-list_3d_icons');
        break;
      default:
        break;
    }
    return res.join(' ');
  };

  const inputType = (items.length === 1) ? 'checkbox' : 'radio';

  return (
    <div className={OptionListClassName(variant)}>
      {items.map((_) => (
        <OptionItem
          id={_.id}
          name={name}
          key={`opt-it-${_.id}`}
          label={_.label}
          modelName={_.modelName}
          checked={_.checked}
          icon={_.icon}
          inputType={inputType}
          eventHandler={eventHandler}
        />
      ))}
    </div>
  );
};

const OptionItem = ({
  id = '', name = '', label = '', checked = false, icon = false, modelName = null, inputType = 'checkbox', eventHandler = () => {}, ...props
}) => {
  const iconComponent = icon ? <img src={`${CONFIG.BASE_PATH}/static/models/icons/${icon}`} alt={label} /> : null;
  return (
    <label className="option__label">
      <input className="hide-input" id={id} type={inputType} data-model-name={modelName} name={name} checked={checked} onChange={eventHandler} />
      <div className="option__item" {...props}>
        {icon && <div className="option__icon">{iconComponent}</div>}
        {label && <div className="option__name">{label}</div>}
        {(checked && icon) && <div className="option__checked"><img src={`${CONFIG.BASE_PATH}/static/icons/icon-check.svg`} alt="выбрано" /></div>}
      </div>
    </label>
  );
};
