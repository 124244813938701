import React, { useRef } from 'react';
import { Provider } from 'react-redux';
import Scene from './components/Scene';
import UI from './components/UI';
import Store from './store';
import ControlPanel from './components/ControlPanel';

function Configurator() {
  const panelRef = useRef(null);

  return (
    <Provider store={Store}>
      <div id="debug-layer" />
      <div className="configurator-adaptive-scene">
        <ControlPanel />
        <Scene panelRef={panelRef} />
      </div>
      <UI panelRef={panelRef} />
    </Provider>
  );
}

export default Configurator;
