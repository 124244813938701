import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ControlComponents/control.css';
import CameraControl from './ControlComponents/CameraControl';
// import MovementControl from './ControlComponents/MovementControl';
import RoomControl from './ControlComponents/RoomControl';
// eslint-disable-next-line import/named
import { setCameraByEvent, setRoomVisibility } from '../store/modelSlice';

const ControlPanel = () => {
  const dispatch = useDispatch();

  const isVisibleRoom = useSelector((state) => state.model.isVisibleRoom);

  const cameraEvent = (eventName) => {
    dispatch(setCameraByEvent({ eventName }));
  };

  const roomEvent = () => {
    dispatch(setRoomVisibility({ isVisible: !isVisibleRoom }));
  };

  return (
    <div className="control-panel">
      <div className="control-wrapper">
        {/* <MovementControl movementEvent={() => {
        // ToDo: it will be necessary to complete movementControl
        }}
        /> */}
        <CameraControl cameraEvent={cameraEvent} />
        <RoomControl handleEvent={roomEvent} selected={isVisibleRoom} />
      </div>
    </div>
  );
};

export default ControlPanel;
