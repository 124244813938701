import React, { useEffect, useState } from 'react';
import {
  Model as ModelImport, withScene
} from 'react-babylonjs';
import useFetch from 'react-fetch-hook';
import {
  Texture, Vector3
} from '@babylonjs/core';
import Store from '../store';
import {
  setModelName,
  updateStateFromSpecs,
  setModelEditing,
  setRoomVisibility
} from '../store/modelSlice';
// eslint-disable-next-line import/no-cycle
import CONFIG from '../config';
import ErrorModel from './scene/ErrorModel';
import LoaderNotAnimation from './scene/LoaderNotAnimation';

const getInitialState = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const initialState = {};
  for (const key of searchParams.keys()) {
    if (key === 'set') {
      initialState.set = searchParams.get(key).split(',');
    } else {
      initialState[key] = searchParams.get(key);
    }
  }

  return initialState;
};

const checkOnHomePage = (modelName) => modelName && modelName === CONFIG.HOME_NAME;

const Model = ({
  xPosition, specs, modelName, position, rotation, visibleNodes = [], ...props
}) => {
  const [model, setModel] = useState(null);
  const [mainMaterial, setMainMaterial] = useState(null);
  const [loaderVisible, setLoaderVisible] = useState(false);

  const createMaterialByTexture = async (materialName, pathToTexture, mainMaterial) => {
    const { scene } = window;
    if (scene && materialName && pathToTexture && mainMaterial) {
      const texturePromise = new Promise((resolve, reject) => {
        const texture = new Texture(pathToTexture, scene, false, true, Texture.TRILINEAR_SAMPLINGMODE, () => {
          texture.uScale = 20;
          texture.vScale = 10;
          resolve(texture);
        }, () => reject(null));
      });
      const texture = await texturePromise;
      const material = mainMaterial.clone();
      material.id = materialName;
      material.name = materialName;
      material.albedoTexture = texture;
      return material;
    }
    return null;
  };

  const getMaterialByName = async (materialName, pathToTexture) => {
    const { scene } = window;
    const material = scene && scene.materials.find((material) => material.name === materialName);
    if (!material) {
      const customMaterial = await createMaterialByTexture(materialName, pathToTexture, mainMaterial);
      if (customMaterial) return customMaterial;
      return null;
    }
    setMainMaterial(material);
    return material;
  };

  const setBackFaceCulling = ({ meshes }) => meshes.forEach((mesh) => {
    if (mesh.material && mesh.material.name !== 'wood') {
      mesh.material.backFaceCulling = true;
    }
  });

  const setPositionAndRotation = (model) => {
    if (model) {
      model.rootMesh.position = new Vector3(position.x + xPosition, position.y, position.z);
      model.rootMesh.rotation = new Vector3(rotation.x, rotation.y, rotation.z);
    }
  };

  const removeUnusedModels = (scene, modelName) => {
    if (scene) {
      scene.rootNodes.forEach((mesh) => {
        if (mesh.getClassName() === 'AbstractMesh' && mesh.name.split('.')[0] !== modelName) mesh.dispose();
      });
    }
  };

  const createShadowsForMesh = (mesh) => {
    const { shadowGenerator, scene } = window;
    if (shadowGenerator && scene) {
      const setReceiveShadows = (mesh) => mesh.receiveShadows = true;
      const recursAllMeshes = (meshes) => {
        meshes.forEach((mesh) => {
          if (mesh.getClassName() === 'Mesh') {
            shadowGenerator.addShadowCaster(mesh);
            setReceiveShadows(mesh);
          }
          recursAllMeshes(mesh.getChildren());
        });
      };
      shadowGenerator.addShadowCaster(mesh);
      // recursAllMeshes([mesh]);
    }
  };

  const initialState = getInitialState();

  useEffect(() => {
    if (initialState && initialState.model) {
      Store.dispatch(setModelName({ modelName: initialState.model }));
      Store.dispatch(setModelEditing({ isEditing: true }));
      Store.dispatch(setRoomVisibility({ isVisible: false }));
    }
  }, []);

  // load model specifications
  const [error, setError] = useState(false);

  useFetch(CONFIG.MODEL_SPEC_URL(modelName), {
    formatter: (response) => response.json().then((r) => {
      Store.dispatch(updateStateFromSpecs(r, initialState));
    }).catch((e) => {
      setError('спецификация модели не загружена');
      console.log(e);
    })
  });

  useEffect(() => {
    removeUnusedModels(window.scene, modelName);
  }, [model]);

  useEffect(() => {
    setLoaderVisible(true);
  }, [modelName]);

  useEffect(() => {
    const { scene } = window;
    if (scene) {
      const modelOnScene = scene.rootNodes.find((obj) => modelName === obj.name.split('.')[0] && obj.getChildren().length);
      const groupWallRoom = scene.rootNodes.find((mesh) => mesh.name === 'groupWallRoom');
      const groupRoom = scene.rootNodes.find((mesh) => mesh.name === 'groupRoom');
      if (groupWallRoom && groupRoom) {
        groupRoom.position.x = xPosition;
        groupWallRoom.position.x = xPosition;
      }
      if (modelOnScene) {
        const { x: posX = 0, y: posY = 0, z: posZ = -2 } = position;
        const { x: rotX = 0, y: rotY = Math.PI / 180, z: rotZ = 0 } = rotation;
        modelOnScene.position = new Vector3(posX + xPosition, posY, posZ);
        modelOnScene.rotation = new Vector3(rotX, rotY, rotZ);
      }
    }
  }, [model, xPosition, position, rotation]);

  // update when visibleNodes change
  useEffect(() => {
    if (model && window.scene) {
      const group = window.scene.rootNodes.find((obj) => modelName === obj.name.split('.')[0] && obj.getChildren().length);
      const modelObjects = group ? group.getChildren() : [];

      const parseObjects = async (modelObject, parentId, material = null, pathToTexture = '') => {
        const receivedMaterial = await getMaterialByName(material, pathToTexture);
        if (material && receivedMaterial) {
          if (modelObject.name === parentId) {
            if (modelObject.getChildren() && modelObject.getChildren().length) {
              modelObject.getChildren().forEach((modelSubObject) => modelSubObject.material = receivedMaterial);
            } else modelObject.material = receivedMaterial;
          } else if (modelObject.getChildren() && modelObject.getChildren().length) {
            modelObject.getChildren().forEach((modelSubObject) => parseObjects(modelSubObject, parentId, material));
          }
        }
      };

      const recursObjects = (modelObject, visibleArray, visible = false) => {
        if (!visible) visible = visibleArray.includes(modelObject.name);
        if (modelObject.getChildren() && modelObject.getChildren().length) {
          visible = visibleArray.includes(modelObject.name);
          modelObject.getChildren().forEach((modelSubObject) => recursObjects(modelSubObject, visibleArray, visible));
        } else modelObject.isVisible = visible;
      };

      const setVisibleObjects = (objectsCollection, visibleArray) => {
        objectsCollection.forEach((modelObject) => {
          recursObjects(modelObject, visibleArray);
        });
      };

      const setMaterials = (objectsCollection, visibleNodes, specs) => {
        if (specs && specs.parts) {
          specs.parts.forEach((part) => {
            if (part.type === 'select-material') {
              // eslint-disable-next-line max-len
              const selectedItem = part.parts && part.parts.find((partChild) => (visibleNodes.includes(partChild.id) ? partChild.material : null));
              const material = selectedItem && selectedItem.material ? selectedItem.material : null;
              const icon = selectedItem && selectedItem.icon ? selectedItem.icon : null;
              const parentId = part.id;
              const pathToTexture = icon ? `${CONFIG.BASE_PATH}/static/models/icons/${icon}` : '';

              objectsCollection.forEach((modelObject) => {
                parseObjects(modelObject, parentId, material, pathToTexture);
              });
            }
          });
        }
      };
      // ----
      if (!checkOnHomePage(modelName)) {
        setMaterials(modelObjects, visibleNodes, specs);
        if (visibleNodes && visibleNodes.length) setVisibleObjects(modelObjects, visibleNodes);
      } else if (model && model.scene) {
        model.scene.isVisible = true;
      }
    }
  }, [visibleNodes, specs, modelName, model]);

  return error
    ? (
      <ErrorModel error={error || 'Ошибка загрузки спецификации'} xPosition={xPosition} />
    )
    : (
      <>
        <ModelImport
          key={modelName}
          rootUrl={CONFIG.ROOT_URL}
          onLoadProgress={() => {
            setLoaderVisible(true);
          }}
          sceneFilename={CONFIG.MODEL_NAME(modelName)}
          onModelLoaded={(model) => {
            createShadowsForMesh(model.rootMesh);
            setBackFaceCulling(model);
            setPositionAndRotation(model);
            setModel(model);
            setLoaderVisible(false);
          }}
        />
        <LoaderNotAnimation
          visible={loaderVisible}
          xPosition={xPosition}
        />
      </>
    );
};

export default withScene(Model);

/*
        const setVisibleObjectsDeep = (objectsCollection, visibleArray) => {
            objectsCollection.forEach(modelObject => {
                if (modelObject.children.length) {
                    modelObject.visible = true;
                    setVisibleObjects(modelObject.children, visibleArray);
                } else {
                    console.log(modelObject.name);
                    modelObject.visible = visibleArray.includes(modelObject.name);
                }
            });
        };
*/
