import React from 'react';
import UserProgress from './UserProgress';

const Fetching = React.memo(({ fetching, children }) => (
  fetching
    ? (
      <UserProgress />
    )
    : children
));

export default Fetching;
