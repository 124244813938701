import * as React from 'react';
import CONFIG from '../../config';

const HomeButton = ({ handleAction }) => (
  <a
    href="/#"
    className="button__home"
    type="button"
    onClick={(e) => {
      e.preventDefault();
      handleAction(null, CONFIG.HOME_NAME);
      return false;
    }}
  >
    <img src={`${CONFIG.BASE_PATH}/static/icons/icon-home.svg`} alt="" />
  </a>
);

export default HomeButton;
