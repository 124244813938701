import React, { useState } from 'react';
import CONFIG from '../../config';
import { positions } from '../../store/modelSlice';

const CameraControl = ({ cameraEvent }) => {
  const [selected, setSelected] = useState(positions[0]);

  const ImgPositionLeft = () => <img className="button__icons" src={`${CONFIG.BASE_PATH}/static/icons/icon-position-left.svg`} alt="left" />;
  const ImgPositionRight = () => <img className="button__icons" src={`${CONFIG.BASE_PATH}/static/icons/icon-position-right.svg`} alt="right" />;
  const ImgPositionBehind = () => <img className="button__icons" src={`${CONFIG.BASE_PATH}/static/icons/icon-position-behind.svg`} alt="behind" />;
  const ImgPositionInFront = () => <img className="button__icons" src={`${CONFIG.BASE_PATH}/static/icons/icon-position-in-front.svg`} alt="in-front" />;

  const eventHandler = (e) => {
    const eventName = e.currentTarget.id;
    cameraEvent(eventName);
    setSelected(eventName);
  };

  return (
    <div className="control camera-control">
      <button className="button camera-button" id={positions[0]} onClick={eventHandler} data-selected={selected === positions[0]}>
        <ImgPositionLeft />
      </button>
      <button className="button camera-button" id={positions[1]} onClick={eventHandler} data-selected={selected === positions[1]}>
        <ImgPositionRight />
      </button>
      <button className="button camera-button" id={positions[2]} onClick={eventHandler} data-selected={selected === positions[2]}>
        <ImgPositionBehind />
      </button>
      <button className="button camera-button" id={positions[3]} onClick={eventHandler} data-selected={selected === positions[3]}>
        <ImgPositionInFront />
      </button>
    </div>
  );
};

export default CameraControl;
