import React from 'react';

const RoomControl = ({ handleEvent, selected }) => {
  const eventHandler = (e) => handleEvent();

  const RoomIcon = ({ fill }) => (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.7719 1H10.076V18.6959H27.7719V1Z" fill={fill} />
      <path d="M9.84794 1.00098L1 9.84892V27.5448L9.84794 18.6969V1.00098Z" fill={fill} />
      <path
        d="M27.4717 18.6962L10.076 18.6962M10.076 18.6962L10.076 1.00034M10.076 18.6962L0.999992 27.4717"
        stroke="#8E8E8E"
        strokeWidth="1.51267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="m18.15022,10.09655l-16.77987,0l0,16.77987l16.77987,0l0,-16.77987z" fill={fill} />
      <path
        d="M1 9.84941H18.6959M1 9.84941V27.5453H18.6959M1 9.84941L9.84794 1.00146H27.5438M18.6959 9.84941V27.5453M18.6959 9.84941L27.5438 1.00146M18.6959 27.5453L27.5438 18.6973V1.00146"
        stroke="black"
        strokeWidth="1.51267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div className="control camera-control">
      <button className="button camera-button button__no-padding button__with__svg" onClick={eventHandler} data-selected={selected}>
        <RoomIcon fill={selected ? '#00A5D7' : 'transparent'} />
      </button>
    </div>
  );
};


export default RoomControl;
