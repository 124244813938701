import React from 'react';

const UserProgress = (props) => {
  return (
    <div className="loading-block">
      <div className="loader">Loading...</div>
    </div>
  );
};

export default UserProgress;
