const CONFIG = {
  BASE_PATH: process.env.PUBLIC_URL,
  HOST: process.env.REACT_APP_HOST,
  MODEL_URL_PREFIX: process.env.REACT_APP_MODEL_URL_PREFIX,
  MODEL_URL_SUFFIX: process.env.REACT_APP_MODEL_URL_SUFFIX,
  MODEL_SPEC_URL_PREFIX: process.env.REACT_APP_MODEL_SPEC_URL_PREFIX,
  MODEL_SPEC_URL_SUFFIX: process.env.REACT_APP_MODEL_SPEC_URL_SUFFIX,
  NODE: process.env.NODE_ENV,
  VR_EXPORT_PATH: 'http://127.0.0.1:9099/save-config',
  BASKET_EXPORT_PATH: '//ume.angelsit.ru/configurator/api/basket.php',
  HOME_NAME: 'production',
  MIN_DESKTOP_WIDTH: 768
};

CONFIG.ROOT_URL = `${CONFIG.HOST}${CONFIG.BASE_PATH}${CONFIG.MODEL_URL_PREFIX}`;
CONFIG.MODEL_NAME = (modelName) => `${modelName}${CONFIG.MODEL_URL_SUFFIX}`;
CONFIG.MODEL_URL = (modelName) => `${CONFIG.HOST}${CONFIG.BASE_PATH}${CONFIG.MODEL_URL_PREFIX}${modelName}${CONFIG.MODEL_URL_SUFFIX}`;
CONFIG.MODEL_SPEC_URL = (modelName) => `${CONFIG.HOST}${CONFIG.BASE_PATH}${CONFIG.MODEL_SPEC_URL_PREFIX}${modelName}${CONFIG.MODEL_SPEC_URL_SUFFIX}`;

export default CONFIG;
