import { createSlice } from '@reduxjs/toolkit';
import { updateVisibleNodes, setCameraById } from './modelSlice';
import CONFIG from '../config';

const initial = {
  title: 'Конфигуратор',
  groups: [],
  presets: [],
  amount: 0,
  invisibleAmount: 0,
  staticItems: 0,
  buttonBasketProcessClass: '',
  buttonVrProcessClass: '',
  returnToBasket: {},
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: initial,
  reducers: {
    updateUIFromSpecs(state, action) {
      const { specs } = action.payload;
      return { ...specsToUI(specs) };
    },
    handleUIAction(state, action) {
      const { listId, itemId } = action.payload;
      const groups = setControlValue(listId, itemId, state.groups);
      state.groups = groups;
      state.amount = getAmount(groups, state.invisibleAmount);
      state.buttonBasketProcessClass = '';
      state.buttonVrProcessClass = '';
    },
    resetToDefault(state, action) {
      const groups = setDefaultProps(state.groups);
      state.groups = groups;
      state.presets = setPresetByPresetId(null, state.presets);
      state.amount = getAmount(groups, state.invisibleAmount);
      state.buttonBasketProcessClass = '';
      state.buttonVrProcessClass = '';
    },
    setPreset(state, action) {
      const { presetId } = action.payload;
      const newPresets = setPresetByPresetId(presetId, state.presets);
      const groups = setControlByPresetId(presetId, newPresets, state.groups);
      state.presets = newPresets;
      state.groups = groups;
      state.amount = getAmount(groups, state.invisibleAmount);
      state.buttonBasketProcessClass = '';
      state.buttonVrProcessClass = '';
    },
    setExportVrButton(state, action) {
      const { buttonVrProcessClass } = action.payload;
      state.buttonVrProcessClass = buttonVrProcessClass;
    },
    setExportBasketButton(state, action) {
      const { buttonBasketProcessClass } = action.payload;
      state.buttonBasketProcessClass = buttonBasketProcessClass;
    },
  }
});

const setPresetByPresetId = (presetId, presets) => {
  const newPresets = [];
  presets.map((item) => (item.id === presetId
    ? newPresets.push({ ...item, checked: !item.checked })
    : newPresets.push({ ...item, checked: false })));

  return newPresets;
};

const getVisibleNodes = (groups = []) => {
  const res = [];
  for (const group of groups) {
    group.content.forEach((control) => {
      switch (control.type) {
        case 'select':
          res.push(control.props.id);
          res.push(control.props.value);
          break;
        case 'checkbox':
          if (control.props.value) {
            res.push(control.props.id);
            res.push(control.props.items[0].id);
          }
          break;
        default:
          break;
      }
    });
  }
  return res;
};

const setControlByPresetId = (presetId, presets = [], groups = []) => {
  let res = [];
  const foundPreset = presets.find((item) => item.id === presetId);
  if (foundPreset && foundPreset.checked) {
    const itemIds = foundPreset.parts;
    itemIds.forEach((itemId) => {
      const control = groups.find((group) => group.content.find((control) => control.props.items.find((item) => item.id === itemId)));
      res = setControlValue(control.id, itemId, groups);
    });
  } else {
    res = setDefaultProps(groups);
  }
  return res;
};

const setDefaultProps = (groups = []) => {
  const res = [];
  groups.forEach((group) => {
    const content = group.content.map((control) => {
      const itemId = control.props.items.length ? control.props.items[0].id : null;
      const updatedControl = { ...control };
      updatedControl.props.value = itemId;
      updatedControl.props.items = control.props.items.map((item) => ({
        ...item,
        checked: item.id === itemId
      }));
      return updatedControl;
    });
    res.push({ ...group, content });
  });
  return res;
};

const setControlValue = (controlId, itemId, groups = []) => {
  const res = [];
  for (const group of groups) {
    const content = group.content.map((control) => {
      if (control.props.id === controlId) {
        const updatedControl = { ...control };
        updatedControl.props.value = (control.type === 'checkbox') ? !updatedControl.props.value : itemId;
        updatedControl.props.items = control.props.items.map((item) => ({
          ...item,
          checked: (control.type === 'checkbox') ? updatedControl.props.value : (item.id === itemId)
        }));
        return updatedControl;
      }
      return { ...control };
    });
    res.push({ ...group, content });
  }
  return res;
};

const specsToUI = ({
  name = 'Configurator', parts = [], presets = [], initialState = []
}) => {
  const groups = [];
  const presetList = [];
  const excludeTypes = ['static'];
  let invisibleAmount = 0;
  const staticItems = [];
  let returnToBasket = {};
  if (initialState && initialState.basketId) {
    returnToBasket = { basketId: initialState.basketId };
  }
  parts.filter((e) => excludeTypes.includes(e.type)).forEach((p) => {
    invisibleAmount = p.parts.reduce((amount, item) => {
      staticItems.push(item);
      return item.price ? item.price + amount : amount;
    }, 0);
  });
  presets.forEach((_, key) => {
    presetList.push({
      id: `preset-${key}`, name: _.name, parts: _.parts, checked: false
    });
  });
  parts.filter((e) => !excludeTypes.includes(e.type)).forEach((p) => {
    const content = [];

    const checkedSelect = (parts, initialState, type = '') => {
      if (!(initialState && initialState.set)) return parts[0].id;
      // eslint-disable-next-line max-len
      const fromInitialState = parts.reduce((checked, item) => (initialState.set.includes(item.article) ? item.id : checked), null);
      if (['select', 'select-material', 'select-3d-icon'].includes(type)) {
        return fromInitialState || parts[0].id;
      }
      return fromInitialState;
    };

    let type = 'Group';
    switch (p.type) {
      case 'select':
        const items = (partsArray, value) => partsArray.map((sp) => (
          {
            id: sp.id,
            article: sp.article,
            label: sp.name,
            checked: value === sp.id,
            icon: sp.icon ? sp.icon : null,
            price: sp.price ? sp.price : 0,
          }
        ));
        const selectedItem = checkedSelect(p.parts, initialState, 'select');
        content.push({
          component: 'OptionList',
          type: 'select',
          props: {
            id: p.id,
            article: p.article,
            name: p.id,
            items: items(p.parts, selectedItem),
            value: selectedItem
          }
        });
        break;
      case 'select-material':
        const materials = (parts, value) => parts.map((sp) => (
          {
            id: sp.id,
            checked: value === sp.id,
            icon: sp.icon ? sp.icon : null,
            article: sp.article,
            price: sp.price ? sp.price : 0,
          }));
        const selectedMaterial = checkedSelect(p.parts, initialState, 'select-material');
        content.push({
          component: 'OptionList',
          type: 'select',
          props: {
            id: p.id,
            name: p.id,
            items: materials(p.parts, selectedMaterial),
            value: selectedMaterial,
            variant: 'round'
          }
        });
        break;
      case 'checkbox':
        const itemsCheckbox = (parts, value) => parts.map((sp) => ({
          id: sp.id, article: sp.article, label: sp.name, checked: value, icon: sp.icon ? sp.icon : null, price: sp.price ? sp.price : 0
        }));
        const checkedItem = !!checkedSelect(p.parts, initialState);
        content.push({
          component: 'OptionList',
          type: 'checkbox',
          props: {
            id: p.id,
            name: p.id,
            variant: 'line',
            items: itemsCheckbox(p.parts, checkedItem),
            value: checkedItem
          }
        });
        break;
      case 'production':
        const production = (parts, value) => parts.map((sp) => ({
          id: sp.id, icon: sp.icon ? sp.icon : null, modelName: sp.modelName
        }));
        type = 'ProductionGroup';
        content.push({
          component: 'OptionList',
          type: 'select',
          props: {
            id: p.id,
            name: p.id,
            items: production(p.parts, true),
            variant: 'production'
          }
        });
        break;
      case 'select-3d-icon':
        const itemWith3dIcon = (parts, value) => parts.map((sp) => (
          {
            id: sp.id,
            article: sp.article,
            checked: value === sp.id,
            icon: sp.icon ? sp.icon : null,
            price: sp.price ? sp.price : 0,
          }
        ));
        const selectedItemWith3dIcon = checkedSelect(p.parts, initialState, 'select-3d-icon');
        content.push({
          component: 'OptionList',
          type: 'select',
          props: {
            id: p.id,
            name: p.id,
            items: itemWith3dIcon(p.parts, selectedItemWith3dIcon),
            value: selectedItemWith3dIcon,
            variant: '3d-icons'
          }
        });
        break;
      default:
        break;
    }
    groups.push({
      id: p.id, title: p.name, type, content
    });
  });
  const amount = getAmount(groups, invisibleAmount);

  return {
    title: name, groups, amount, invisibleAmount, staticItems, returnToBasket, presets: presetList
  };
};

export const {
  updateUIFromSpecs, handleUIAction, resetToDefault, setPreset, setExportVrButton, setExportBasketButton
} = uiSlice.actions;

export const dispatchSetPreset = ({ presetId }) => (dispatch, getState) => {
  dispatch(setPreset({ presetId }));
  const state = getState();
  const visibleNodes = getVisibleNodes(state.ui.groups);
  dispatch(updateVisibleNodes(visibleNodes));
};

export const dispatchResetProps = () => (dispatch, getState) => {
  dispatch(resetToDefault({}));
  const state = getState();
  const visibleNodes = getVisibleNodes(state.ui.groups);
  dispatch(updateVisibleNodes(visibleNodes));
};

export const dispatchUIAction = ({ listId, itemId }) => (dispatch, getSate) => {
  dispatch(handleUIAction({ listId, itemId }));
  const state = getSate();
  const visibleNodes = getVisibleNodes(state.ui.groups);
  dispatch(updateVisibleNodes(visibleNodes));
  dispatch(setCameraById(itemId));
};

export const getAmount = (groups, invisibleAmount) => {
  let amountSum = 0;
  for (const group of groups) {
    const amount = group.content.reduce((amount, control) => (amount + control.props.items.reduce((price, item) => (item.checked ? item.price : price), 0)), 0);
    amountSum += amount;
  }
  return amountSum + invisibleAmount;
};

const getBasketData = (groups, title, staticItems, picture = null, returnToBasket = {}) => {
  console.log('groups',groups)
  const selectedItems = [];
  if (groups.length) {
    for (const group of groups) {
      group.content.map((control) => (control.props.items.map((item) => {
        if (item.checked) selectedItems.push(item.article);
        return 0;
      })));
    }
  }
  if (staticItems.length) {
    for (const item of staticItems) {
      selectedItems.push(item.article);
    }
  }
  return {
    ...returnToBasket,
    name: title || '',
    parts: selectedItems,
    picture: picture || '',
  };
};
const getVrData = (groups, title, staticItems) => {
  const selectedItems = [];
  if (groups.length) {
    for (const group of groups) {
      group.content.map((control) => (control.props.items.map((item) => {
        if (item.checked) selectedItems.push(item.id);
        return 0;
      })));
    }
  }
  if (staticItems.length) {
    for (const item of staticItems) {
      selectedItems.push(item.id);
    }
  }
  return {
    id: title,
    components: selectedItems,
  };
};

export const dispatchExportVrAction = () => (dispatch, getState) => {
  dispatch(setExportVrButton({ buttonVrProcessClass: 'button_spin' }));
  const state = getState();
  const vrJson = getVrData(state.ui.groups, state.model.modelName, state.ui.staticItems);
  const url = `${CONFIG.VR_EXPORT_PATH}`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'no-cors',
    body: JSON.stringify([vrJson])
  };
  fetch(url, params)
    .then((response) => response)
    .then(
      (response) => {
        dispatch(setExportVrButton({ buttonVrProcessClass: 'button_success' }));
      },
      (error) => {
        dispatch(setExportVrButton({ buttonVrProcessClass: 'button_error' }));
      }
    );

  return true;
};

export const dispatchExportBasketAction = (picture) => (dispatch, getState) => {
  dispatch(setExportBasketButton({ buttonBasketProcessClass: 'button_spin' }));
  const state = getState();
  const basketJson = getBasketData(state.ui.groups, state.ui.title, state.ui.staticItems, picture, state.ui.returnToBasket);
  const url = `${CONFIG.BASKET_EXPORT_PATH}`;
  const params = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify([basketJson]) // body data type must match "Content-Type" header
  };
  fetch(url, params)
    .then((response) => response.json())
    .then(
      (response) => {
        const className = response.stat ? 'button_success' : 'button_error';
        dispatch(setExportBasketButton({ buttonBasketProcessClass: className }));
      },
      (error) => {
        dispatch(setExportBasketButton({ buttonBasketProcessClass: 'button_error' }));
      }
    );
  return 0;
};

export default uiSlice.reducer;
