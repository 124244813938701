import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { updateUIFromSpecs } from './uiSlice';

export const positions = ['position-left', 'position-right', 'position-behind', 'position-in-front'];

const DEFAULT_CAMERA_PARAMS = {
  position: [1, 1, 3],
  target: [0, 0, 0],
  fov: 60
};

const DEFAULT_MODEL_PARAMS = {
  position: [0, 0, -2],
  rotation: [0, Math.PI / 180, 0]
};

const initial = {
  specs: null,
  visibleNodes: [],
  visibleAlways: [],
  modelName: 'production',
  cameraParams: DEFAULT_CAMERA_PARAMS,
  cameraParamsPrev: DEFAULT_CAMERA_PARAMS,
  modelParams: DEFAULT_MODEL_PARAMS,
  isVisibleRoom: false,
  isModelEditing: false,
  fetching: false,
};

const modelSlice = createSlice({
  name: 'model',
  initialState: initial,
  reducers: {
    setSpecs(state, action) {
      const { specs, initialState } = action.payload;
      const visibleNodes = extractDefaultParts(specs && specs.parts, initialState);
      return {
        ...state,
        visibleNodes: [...visibleNodes],
        specs,
        visibleAlways: extractAlwaysVisibleParts(specs && specs.parts),
        fetching: false
      };
    },
    updateVisibleNodes(state, action) {
      state.visibleNodes = [...state.visibleAlways, ...action.payload];
    },
    setRoomVisibility(state, action) {
      const { isVisible } = action.payload;
      state.isVisibleRoom = isVisible;
    },
    setModelEditing(state, action) {
      const { isEditing } = action.payload;
      state.isModelEditing = isEditing;
    },
    setModelName(state, action) {
      const { modelName } = action.payload;
      state.modelName = modelName;
      state.fetching = true;
    },
    setCamera(state, action) {
      const cameraParams = { ...DEFAULT_CAMERA_PARAMS, ...action.payload, useTransition: false };
      return { ...state, cameraParams, cameraParamsPrev: cameraParams };
    },
    setCameraById(state, action) {
      const camera = findCameraParams(state.specs, action.payload);
      if (!camera.dontMove && (camera.position || camera.target || camera.fov)) {
        const cameraParams = { ...DEFAULT_CAMERA_PARAMS, ...camera, useTransition: true };
        return { ...state, cameraParams, cameraParamsPrev: state.cameraParams };
      }
      return state;
    },
    setCameraByEvent(state, action) {
      const { eventName } = action.payload;
      const camera = getCameraParamsByEventName(eventName, state.specs);
      const cameraParams = { ...DEFAULT_CAMERA_PARAMS, ...camera, useTransition: false };
      return { ...state, cameraParams, cameraParamsPrev: state.cameraParams };
    },
    setModelParams(state, action) {
      const { modelParams } = action.payload;
      return { ...state, modelParams };
    },
    setFetching(state, action) {
      state.fetching = action.payload;
    },
  }
});

export const {
  setSpecs,
  setCamera,
  setCameraById,
  updateVisibleNodes,
  setModelName,
  setCameraByEvent,
  setRoomVisibility,
  setModelEditing,
  setModelParams,
  setFetching
} = modelSlice.actions;

const getCameraParamsByEventName = (eventName, specs) => {
  const currentCameraParams = specs.camera ? { ...specs.camera } : {};
  const {
    position: [posX, posY, posZ],
    target: [tarX, tarY, tarZ]
  } = currentCameraParams;
  switch (eventName) {
    case positions[0]:
      return {
        position: [5 - posX, 1, -3],
        target: [tarX, tarY, -1],
        fov: 60
      };
    case positions[1]:
      return {
        position: [-4 + posX, 1, -3],
        target: [tarX, tarY, -2],
        fov: 50
      };
    case positions[2]:
      return {
        position: [0 - posX, 1, -6],
        target: [0 - posX, tarY, tarZ],
        fov: 50
      };
    case positions[3]:
      return {
        position: [posX, posY, posZ],
        target: [tarX, tarY, tarZ],
        fov: 50
      };
    default:
      return {
        position: [0 - posX, 1, 3],
        target: [tarX, tarY, tarZ],
        fov: 40
      };
  }
};

const findModelParams = (part) => {
  if (part) {
    const {
      position = DEFAULT_MODEL_PARAMS.position,
      rotation = DEFAULT_MODEL_PARAMS.rotation
    } = part;
    return { position, rotation };
  }
  return DEFAULT_MODEL_PARAMS;
};

const findCameraParams = (part, id = null, parentGroup = null) => {
  const currentCameraParams = part.camera ? { ...part.camera } : {};
  if (id) {
    let cameraParams = {};
    if (part.id && part.id === id) {
      cameraParams = { ...currentCameraParams, current: true, parentGroupId: parentGroup && parentGroup.id };
    } else if (part.parts && part.parts.length) {
      for (const item of part.parts) {
        const params = findCameraParams(item, id, part);
        if (Object.keys(params).length) {
          cameraParams = { ...currentCameraParams, ...params };
          break;
        }
      }
    }
    return cameraParams;
  } return currentCameraParams;
};

export const setCameraToBeginning = () => (dispatch, getState) => {
  const state = getState();
  const camera = findCameraParams(state.model.specs);
  dispatch(setCamera(camera));
};

export const updateStateFromSpecs = (specs, initialState) => (dispatch) => {
  dispatch(setSpecs({ specs, initialState }));
  const camera = findCameraParams(specs);
  dispatch(setCamera(camera));
  const specsExt = { ...specs, initialState };
  dispatch(updateUIFromSpecs({ specs: specsExt }));
  const modelParams = findModelParams(specs);
  dispatch(setModelParams({ modelParams }));
};

const extractAlwaysVisibleParts = (parts = []) => {
  let res = [];
  if (Array.isArray(parts) && parts.length) {
    const defaultParts = parts.map((p) => {
      if (p && p.type) {
        if (p.type === 'static') {
          return [p.id, ...Array.isArray(p.parts) ? p.parts.map((pp) => pp.id) : []];
        }
      }
      return [];
    });
    res = [...res, ...defaultParts.flat()].flat();
  }
  return res;
};


const getDefaultPart = (part, initialState) => {
  if (part && part.type) {
    const checkedSelect = (parts, type = '') => {
      if (!(initialState && initialState.set)) return parts[0].id;
      const fromInitialState = parts.reduce(
        (checked, item) => (initialState.set.includes(item.article) ? item.id : checked),
        null
      );
      if (['select', 'select-material', 'select-3d-icon'].includes(type)) {
        return fromInitialState || parts[0].id;
      }
      return fromInitialState;
    };

    switch (part.type) {
      case 'static':
        return [part.id, ...Array.isArray(part.parts)
          ? part.parts.map((partChildren) => partChildren.id)
          : []];
      case 'select':
      case 'select-material':
      case 'select-3d-icon':
        return [part.id, checkedSelect(part.parts, initialState, part.type)];
      case 'checkbox':
        // eslint-disable-next-line no-case-declarations
        const checked = checkedSelect(part.parts, initialState, part.type);
        if (checked) return [part.id, checked];
        return [];
      default:
        return [];
    }
  } else {
    return [];
  }
};

const extractDefaultParts = (parts = [], initialState) => {
  let res = [];
  if (Array.isArray(parts) && parts.length) {
    const defaultParts = parts.map((part) => getDefaultPart(part, initialState));
    res = [...res, ...defaultParts].flat(Infinity);
  }
  return res;
};


export default modelSlice.reducer;
