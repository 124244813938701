import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import modelReducer from './modelSlice';
import uiReducer from './uiSlice';


const rootReducer = combineReducers({
    model:modelReducer,
    ui:uiReducer,
});

export default configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()]
})

